<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filtros
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>País</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="countryFilter"
            :options="countryOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:countryFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Sector</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="sectorFilter"
            :options="sectorOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:sectorFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Asesor</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="advisorFilter"
            :options="advisorOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:advisorFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    countryFilter: {
      type: [String, null],
      default: null,
    },
    sectorFilter: {
      type: [String, null],
      default: null,
    },
    advisorFilter: {
      type: [String, null],
      default: null,
    },
    countryOptions: {
      type: Array,
      required: true,
    },
    sectorOptions: {
      type: Array,
      required: true,
    },
    advisorOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
